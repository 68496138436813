import type { CountryCode } from '@whoop/i18n/types/address';
import type { Language } from '@whoop/i18n';
import { getRouteForCountryAndLanguage } from '@whoop/i18n/utils/routingHelpers';
import { useShallow } from 'zustand/react/shallow';
import { redirect, useRouter, useSearchParams } from 'next/navigation';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { env } from 'env';

export const useNavigation = (): {
  getLocalizedPath: (path: string) => string;

  /**
   * Navigate to a new page, but allow the member to go back to the previous page using their browser.
   * Handles localizing the path so that the member stays on the correct site for their region + language.
   * Also handles keeping the promo code in the URL for RAF, Affiliate, and Partnership flows.
   * @param path New path to go to, without a slash (example: 'membership' goes to '/us/en/membership')
   */
  goTo: (path: string) => void;

  /**
   * Navigate to a new page, and prevent the member from going back to the current page they're on.
   * This should be used when we want to kick the member off the current page because they shouldn't have access to it, or we don't have sufficient information to power it, etc.
   * Handles localizing the path and maintaining the current RAF, Affiliate, or Partnership code.
   * @param path New path to go to, without a slash (example: '' goes to '/us/en/')
   */
  redirectTo: (path: string) => void;
  externalRedirect: (url: string) => void;
  changeSiteCountryAndLanguage: (
    country: CountryCode,
    language: Language,
  ) => void;
  preloadPage: (path: string) => void;
  searchParams: URLSearchParams;
} => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { language, promoCode, promoContent, promoInfo, country } =
    useSiteStore(
      useShallow((state) => ({
        language: state.language,
        promoCode: state.promoCode,
        promoContent: state.promoContent,
        promoInfo: state.promoInfo,
        country: state.country,
      })),
    );

  const getUrlPromoCode = (): string => {
    // Partnerships should persist in the URL when switching regions or going back to landing page
    if (promoContent?.partnership_name) return promoContent.partnership_name;

    // Affiliate + RAF codes should persist in the URL when switching regions or going back to landing page
    if (
      promoCode?.code &&
      (promoInfo?.referral || promoInfo?.affiliate?.affiliate_code)
    ) {
      return promoCode.code;
    }

    return '';
  };

  const getLocalizedPath = (path: string): string => {
    const urlPromoCode = getUrlPromoCode();
    const pathWithCode = path === '' ? urlPromoCode : path;
    return getRouteForCountryAndLanguage({
      country,
      language,
      path: pathWithCode,
    });
  };

  const goTo = (path: string): void => {
    router.push(getLocalizedPath(path));
  };

  const redirectTo = (path: string): void => {
    redirect(getLocalizedPath(path));
  };

  const externalRedirect = (url: string): void => {
    router.push(url);
  };

  const changeSiteCountryAndLanguage = (
    newCountry: CountryCode,
    newLanguage: Language,
  ): void => {
    // No redirect/refresh required if member clicks on the current country/language again
    if (newCountry === country && newLanguage === language) return;

    const path = getUrlPromoCode();
    const newPath = getRouteForCountryAndLanguage({
      country: newCountry,
      language: newLanguage,
      path,
    });
    window.location.href = `${env.NEXT_PUBLIC_JF_REPLATFORM_URL}${newPath}`;
  };

  const preloadPage = (path: string): void => {
    const newPath = getRouteForCountryAndLanguage({
      country,
      language,
      path,
    });
    router.prefetch(newPath);
  };

  return {
    getLocalizedPath,
    goTo,
    redirectTo,
    externalRedirect,
    changeSiteCountryAndLanguage,
    preloadPage,
    searchParams,
  };
};
